export default {
    // 时间转换
    formatDate(timestamp) {
        if(timestamp){
            let date=new Date(timestamp);
            let year = date.getFullYear().toString().padStart(4, '0');
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            let hour = date.getHours().toString().padStart(2, '0');
            let minute = date.getMinutes().toString().padStart(2, '0');
            let second = date.getSeconds().toString().padStart(2, '0');
            // console.log(`${year}-${month}-${day} ${hour}:${minute}:${second}`); // 2023-02-16 08:25:05
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`
        }else{
            return '';
        }
    },

    // json格式转url参数
    jsonToUrlParam(json) {
        return Object.keys(json).map(key => key + '=' + json[key]).join('&');
    }

}
